import React from 'react';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import { ImageProperties } from '../../types/ImageProperties';


export interface ImageCardProps {
    index: number;
    imageProperties: ImageProperties;
    onClick?: (imageProperties: ImageProperties, index: number) => void
}

const ImageCardContainer = styled.div`
    position: relative;
    flex: 0 0 20%;

    @media (max-width: 768px) {
        flex: 0 0 50%;
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        flex: 0 0 25%;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    div.overlay { 
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(256,256,256);
        opacity: 0;
        transition: opacity 500ms ease-in-out;
        text-align: center;

        &:hover {
            opacity: 0.85;
            cursor: pointer;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #000;
        }
    }
`;

export const ImageCard = (props: ImageCardProps) => {
    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick(props.imageProperties, props.index);
        }
    };
    return (
        <ImageCardContainer onClick={onClickHandler}>
            <picture>
                <source srcSet={props.imageProperties.optimizedThumbnailSrcPath} />
                <source srcSet={props.imageProperties.thumbnailSrcPath} />
                <Image src={props.imageProperties.thumbnailSrcPath} alt={`Dragan Strukelj Original Artwork (thumbnail) - ${props.imageProperties.title}`} />
            </picture>
            <div className='overlay'>
                <span>{props.imageProperties.title}</span>
            </div>
        </ImageCardContainer>
    );
}
