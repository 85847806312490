import React, { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ImageCard } from '../image-card/ImageCard';
import { ImageGalleryConfig } from '../../types/ImageGalleryConfig';
import { ImageProperties } from '../../types/ImageProperties';
import { ImageSlide } from '../image-slide/ImageSlide';


export interface ImageGalleryProps {
    config: ImageGalleryConfig
}

const ImageGalleryWrapper = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
`;

export const ImageGallery = (props: ImageGalleryProps) => {

    const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>();
    const [selectedImage, setSelectedImage] = useState<ImageProperties | undefined>();
    const [showImageSlide, setShowImageSlide] = useState<boolean>(false);

    const onPreviousHandler = useCallback(() => {
        const imageCount = props.config.images.length;

        let imageIndex = selectedImageIndex || 0;
        imageIndex = imageIndex - 1;
        imageIndex = imageIndex >= 0 ? imageIndex : imageCount + imageIndex;

        const currentImageIndex = imageIndex;
        setSelectedImageIndex(currentImageIndex);
        setSelectedImage(props.config.images[currentImageIndex]);
    }, [selectedImageIndex, props.config.images]);

    const onNextHandler = useCallback(() => {
        const imageCount = props.config.images.length
        const imageIndex = selectedImageIndex || imageCount;

        const currentImageIndex = (imageIndex + 1) % imageCount;
        setSelectedImageIndex(currentImageIndex);
        setSelectedImage(props.config.images[currentImageIndex]);
    }, [selectedImageIndex, props.config.images]);

    const onCloseHandler = useCallback(() => {
        setShowImageSlide(false);
    }, []);

    const onImageCardClick = useCallback((imageProperties: ImageProperties, index: number) => {
        setSelectedImageIndex(index);
        setSelectedImage(imageProperties);
        setShowImageSlide(true);
    }, []);

    const renderImages = useCallback((): ReactNode[] => {
        return props.config.images.map((imageProperties: ImageProperties, index: number) => {
            return <ImageCard key={index} index={index} imageProperties={imageProperties} onClick={onImageCardClick} />;
        });
    }, [props.config, onImageCardClick]);

    return (
        <>
            <ImageGalleryWrapper>
                {renderImages()}
            </ImageGalleryWrapper>
            <ImageSlide
                show={showImageSlide}
                imageProperties={selectedImage}
                onNext={onNextHandler}
                onPrevious={onPreviousHandler}
                onClose={onCloseHandler}
            />
        </>

    );
}