import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '../../components/page/Page';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ContactForm, ContactFormInput } from '../../components/contact-form/ContactForm';
import { NewsletterForm, NewsletterFormInput } from '../../components/newsletter-form/NewsletterForm';
import ContactPageCSS from './contact-page.module.css'
import { NewsletterService } from '../../service/NewsletterService';
import { ContactService } from '../../service/ContactService';
import { ContactSendMessageResponseDto } from '../../dto/ContactSendMessage';
import { NewsletterSubscriptionResponseDto } from '../../dto/NewsletterSubscription';
import { BaseServiceResponse } from '../../dto/BaseService';

export interface ContactPageProps { }

interface AlertContent {
    message: string;
    variant: string;
}

const SEND_MESSAGE_SUCCESS: AlertContent = {
    message: "Message Sent!",
    variant: "success"
}

const SUBSCRIBE_SUCCESS: AlertContent = {
    message: "Thank you for subscribing!",
    variant: "success"
}

const ERROR_4XX: AlertContent = {
    message: "Invalid form, please check your inputs.",
    variant: 'danger'
}

const ERROR_5XX: AlertContent = {
    message: "Something happend, please try again in a few moments.",
    variant: 'danger'
}

const UNKNOWN_ERROR: AlertContent = {
    message: "Unknown error. If it persists, please send us a direct email.",
    variant: 'danger'
}


export const ContactPage = (props: ContactPageProps) => {
    const [alertContent, setAlertContent] = useState<AlertContent | undefined>();
    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);


    const onAlertClose = useCallback(() => {
        setIsAlertVisible(false);
    }, [setIsAlertVisible]);

    useEffect(() => {
        if (isAlertVisible) {
            const timeId = setTimeout(() => {
                setIsAlertVisible(false);
            }, 3500);
            return () => { clearTimeout(timeId) }
        }
    }, [isAlertVisible]);

    const handleServiceResponse = (response: BaseServiceResponse, alertContent: AlertContent) => {
        if (response.status === 200) {
            setAlertContent(alertContent);
        } else {
            setAlertContent(UNKNOWN_ERROR);
        }
        setIsAlertVisible(true);
    }

    const handleServiceError = (error: any) => {
        if (error.response.status === 400) {
            setAlertContent(ERROR_4XX);
        } else if (error.response.status === 500) {
            setAlertContent(ERROR_5XX)
        }
        setIsAlertVisible(true);
    }

    const onContactFormSubmit = useCallback(async (contactFormInput: ContactFormInput) => {
        try {
            const sendMessageResponse: ContactSendMessageResponseDto = await ContactService.sendMessage({ ...contactFormInput });
            handleServiceResponse(sendMessageResponse, SEND_MESSAGE_SUCCESS);
        } catch (error: any) {
            handleServiceError(error);
        }
    }, []);

    const onNewsletterFormSubmit = useCallback(async (newsletterFormInput: NewsletterFormInput) => {
        try {
            const subscriptionResponse: NewsletterSubscriptionResponseDto = await NewsletterService.subscribe({ ...newsletterFormInput });
            handleServiceResponse(subscriptionResponse, SUBSCRIBE_SUCCESS);
        } catch (error: any) {
            handleServiceError(error);
        }

    }, []);

    return (
        <>
            <div className='position-fixed top-0 end-0 mx-3 mt-3 '>
                <Alert show={isAlertVisible} variant={alertContent?.variant} dismissible={true} onClose={onAlertClose}>
                    {alertContent?.message}
                </Alert>
            </div>

            <Page>
                <Container>
                    <Row>
                        <Col lg='6'>
                            <h1 className={ContactPageCSS.sectionHeader}>Contact Form</h1 >
                            <p>
                                If you have any questions please fill out the form bellow and send us a message.
                                Otherwise, e-mail us at: <a href="mailto:dragan@draganstrukelj.com">dragan@draganstrukelj.com</a>
                            </p>
                            <div className='my-5'>
                                <ContactForm onSubmt={onContactFormSubmit} />
                            </div>
                        </Col>
                        <Col lg='6'>
                            <h1 className={ContactPageCSS.sectionHeader}> Newsletter</h1 >
                            <p>Subscribe to our Newsletter and we will keep you informed about any new developments.</p>
                            <div className='my-5'>
                                <NewsletterForm onSubmit={onNewsletterFormSubmit} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Page>

        </>
    );
}