import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ContactFormCSS from './contact-form.module.css'
import { useEffect, useState } from 'react';
import { MESSAGE_MAX_LENGTH, messageValidation, nameValidation, subjectValidation } from './ContactForm.validation';
import { emailValidation } from '../form/validation/common.validation';


export interface ContactFormInput {
    name: string;
    email: string;
    subject?: string;
    message: string;
}

export interface ContactFormProps {
    onSubmt: (contactFormInput: ContactFormInput) => void;
}

export const ContactForm = (props: ContactFormProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [messageLength, setMessageLength] = useState<number>(0);
    const { control, formState, handleSubmit, watch, reset } = useForm<ContactFormInput>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
    });

    const messageWatch = watch('message');
    useEffect(() => {
        setMessageLength(messageWatch.length);
    }, [messageWatch]);


    const onSubmit: SubmitHandler<ContactFormInput> = async (contactFormInput: ContactFormInput) => {
        setLoading(true);
        await props.onSubmt(contactFormInput)
        setLoading(false);
        reset();
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <Controller
                            name='name'
                            control={control}
                            rules={nameValidation}
                            render={({ field }) => {
                                return <>
                                    <Form.Group controlId='name'>
                                        <Form.Label>name (required):</Form.Label>
                                        <Form.Control {...field} type='input' className={ContactFormCSS.contactFormInput} />
                                    </Form.Group>
                                    <Form.Text className={`text-danger ${ContactFormCSS.errorText}`}>
                                        {formState.errors.name ? formState.errors.name.message : ""}
                                    </Form.Text>
                                </>
                            }}
                        />
                    </Col>
                    <Col>
                        <Controller
                            name='email'
                            control={control}
                            rules={emailValidation}
                            render={({ field }) => {
                                return <>
                                    <Form.Group controlId='email'>
                                        <Form.Label>e-mail (required):</Form.Label>
                                        <Form.Control {...field} type='input' className={ContactFormCSS.contactFormInput} />
                                    </Form.Group>
                                    <Form.Text className={`text-danger ${ContactFormCSS.errorText}`}>
                                        {formState.errors.email ? formState.errors.email.message : ""}
                                    </Form.Text>
                                </>
                            }}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Controller
                            name='subject'
                            control={control}
                            rules={subjectValidation}
                            render={({ field }) => {
                                return <>
                                    <Form.Group controlId='subject'>
                                        <Form.Label>subject (optional):</Form.Label>
                                        <Form.Control {...field} type='input' className={ContactFormCSS.contactFormInput} />
                                    </Form.Group>
                                    <Form.Text className={`text-danger ${ContactFormCSS.errorText}`}>
                                        {formState.errors.subject ? formState.errors.subject.message : ""}
                                    </Form.Text>
                                </>
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Controller
                            name='message'
                            control={control}
                            rules={messageValidation}
                            render={({ field }) => {
                                return <>
                                    <Form.Group controlId='message'>
                                        <Form.Label>message (required):</Form.Label>
                                        <Form.Control {...field} as='textarea' className={ContactFormCSS.contactFormInput} rows={5} />
                                    </Form.Group>
                                    <Form.Text className={`text-danger ${ContactFormCSS.errorText}`}>
                                        {formState.errors.message ? formState.errors.message.message : ""}
                                    </Form.Text>
                                </>
                            }}
                        />
                        <span className={` ${ContactFormCSS.messageCounter} float-end ${messageLength > MESSAGE_MAX_LENGTH ? 'text-danger' : ''}`}>
                            {messageLength} / {MESSAGE_MAX_LENGTH}
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button className={`float-end ${ContactFormCSS.submitButton}`} type="submit">
                            {loading && <Spinner className={` ${ContactFormCSS.submitSpinner}`} animation='border' size='sm' variant='dark'></Spinner>}
                            {loading && 'Sending...'}
                            {!loading && 'Send Message'}
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    );
}