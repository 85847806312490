import { NewsletterSubscriptionRequestDto, NewsletterSubscriptionResponseDto } from "../dto/NewsletterSubscription";
import { API } from 'aws-amplify';
import { BaseService } from "./BaseService";


export class NewsletterService extends BaseService {

    static async subscribe(newsletterSubscription: NewsletterSubscriptionRequestDto): Promise<NewsletterSubscriptionResponseDto> {
        return await API.post(this.API_NAME, this.NEWSLETTER_ENDPOINT, { body: newsletterSubscription });
    }
}