import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ASSET_IMAGES_PATH, INSTAGRAM_LINK } from '../../constants';
import styled from 'styled-components';
import HeaderCSS from './header.module.css'
import { Link, useLocation } from "react-router-dom";
import { PageRoute } from '../../Router';

export interface HeaderProps { }

const HeaderWrapper = styled.div`
    margin-top: 25px;
`;

export const Header = (props: HeaderProps) => {
    const location = useLocation();
    return (
        <HeaderWrapper>
            <Container>
                <Navbar expand='md'>
                    <Navbar.Brand as={Link} to={PageRoute.ROOT} style={{ marginRight: '5%' }}>
                        <Image src={`${ASSET_IMAGES_PATH}/signature7.png`} className={HeaderCSS.signatureImage} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className={`flex-grow-1 justify-content-between ${HeaderCSS.headerNavigation}`}>
                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    to={PageRoute.ROOT}
                                    className={`${HeaderCSS.navigationLink} ${location.pathname === PageRoute.ROOT.toString() ? HeaderCSS.selectedNavigationLink : ""}`}>
                                    Artwork
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    to={PageRoute.ABOUT}
                                    className={`${HeaderCSS.navigationLink} ${location.pathname.startsWith(PageRoute.ABOUT) ? HeaderCSS.selectedNavigationLink : ""}`}>
                                    About
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    to={PageRoute.CONTACT}
                                    className={`${HeaderCSS.navigationLink} ${location.pathname.startsWith(PageRoute.CONTACT) ? HeaderCSS.selectedNavigationLink : ""}`}>
                                    Contact
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href={INSTAGRAM_LINK} target='_blank' rel="noreferrer" className={`${HeaderCSS.navigationLink} ${HeaderCSS.overrideActive}`} >Instagram</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </HeaderWrapper >
    );
}