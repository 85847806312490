import React, { useState } from 'react';
import NewsletterFormCSS from './newsletter-form.module.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { emailValidation } from '../form/validation/common.validation';


export interface NewsletterFormInput {
    email: string;
}

export interface NewsletterFormProps {
    onSubmit: (data: NewsletterFormInput) => void
}


export const NewsletterForm = (props: NewsletterFormProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { control, formState, handleSubmit, reset } = useForm<NewsletterFormInput>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
        },
    });

    const onSubmit: SubmitHandler<NewsletterFormInput> = async (inputData: NewsletterFormInput) => {
        setLoading(true);
        await props.onSubmit(inputData);
        setLoading(false);
        reset();
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <Controller
                            name='email'
                            control={control}
                            rules={emailValidation}
                            render={({ field }) => {
                                return <>
                                    <Form.Group controlId='email'>
                                        <Form.Label>e-mail (required):</Form.Label>
                                        <Form.Control {...field} type='input' className={NewsletterFormCSS.newsletterFormInput} />
                                    </Form.Group>
                                    <Form.Text className={`text-danger ${NewsletterFormCSS.errorText}`}>
                                        {formState.errors.email ? formState.errors.email.message : ""}
                                    </Form.Text>
                                </>
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className={`float-end ${NewsletterFormCSS.submitButton}`} type="submit" disabled={loading}>
                            {loading && <Spinner className={` ${NewsletterFormCSS.submitSpinner}`} animation='border' size='sm' variant='dark'></Spinner>}
                            {loading && 'Subscribing...'}
                            {!loading && 'Subscribe'}
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    );
}