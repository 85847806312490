import React from 'react';
import { Page } from '../../components/page/Page';
import { ImageGallery } from '../../components/image-gallery/ImageGallery';
import { IMAGE_GALLERY_CONFIG } from '../../config/ImageGalleryConfig';


export interface HomePageProps { }

export const HomePage = (props: HomePageProps) => {
    return (
        <Page>
            <ImageGallery config={IMAGE_GALLERY_CONFIG} />
        </Page>
    );
}