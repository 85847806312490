import { ASSET_IMAGES_PATH } from '../constants';
import { ImageGalleryConfig } from '../types/ImageGalleryConfig';

export const IMAGE_GALLERY_CONFIG: ImageGalleryConfig = {
    images: [
        {
            id: 'slumber.jpg',
            title: 'Slumber',
            dimensions: 'H 152.40 x W 76.20 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/slumber-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/slumber-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/slumber-21.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/slumber.webp`,
        },
        {
            id: 'levitation.jpg',
            title: 'Levitation',
            dimensions: 'H 121.92 x W 91.44 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/levitation-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/levitation-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/levitation.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/levitation.webp`,
        },
        {
            id: 'untitled-21.jpg',
            title: 'Untitled #20',
            dimensions: 'H 182.88 x W 91.44 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-21-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-21-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-21.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-21.webp`,
        },
        {
            id: 'from-the-beyond.jpg',
            title: 'From The Beyond',
            dimensions: 'H 121.92 x W 60.96 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumbfrom-the-beyond.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/from-the-beyond-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/from-the-beyond.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/from-the-beyond.webp`,
        },
        {
            id: 'tightrope.jpg',
            title: 'Tightrope',
            dimensions: 'H 182.88 x W 76.2 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/tightrope-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/tightrope-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/tightrope.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/tightrope.webp`,
        },
        {
            id: 'cell.jpg',
            title: 'Cell',
            dimensions: 'H 182.88 x W 91.44 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/cell-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/cell-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/cell.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/cell.webp`,
        },
        {
            id: 'reflection.jpg',
            title: 'Reflection',
            dimensions: 'H 182.88 x W 91.44 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/reflection-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/reflection-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/reflection.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/reflection.webp`,
        },
        {
            id: 'untitled-20.jpg',
            title: 'Untitled #20',
            dimensions: 'H 48.26 x W 33.02 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-20-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-20-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-20.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-20.webp`,
        },
        {
            id: 'swing.jpg',
            title: 'Swing',
            dimensions: 'H 43.18 x W 33.02 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/swing-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/swing-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/swing.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/swing.webp`,
        },
        {
            id: 'sunset.jpg',
            title: 'Sunset',
            dimensions: 'H 45.72 x W 35.56 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/sunset-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/sunset-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/sunset.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/sunset.webp`,
        },
        {
            id: 'whats-the-alternative.jpg',
            title: 'What\'s the Alternative?',
            dimensions: 'H 121.5 x W 91 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/whats-the-alternative-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/whats-the-alternative-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/whats-the-alternative.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/whats-the-alternative.webp`,
        },
        {
            id: 'free-fall-19.jpg',
            title: 'Free Fall',
            dimensions: 'H 60.9 x W 76.2 cm',
            medium: 'Oil On Canvas',
            year: '2024',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/free-fall-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/free-fall-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/free-fall.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/free-fall.webp`,
        },
        {
            id: 'untitled-19.jpg',
            title: 'Untitled #19',
            dimensions: 'H 101.6 x W 76.2 cm',
            medium: 'Oil On Linen',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-19-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-19-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-19.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-19.webp`,
        },
        {
            id: 'on-the-other-side-19.jpg',
            title: 'On The Other Side',
            dimensions: 'H 60.9 x W 60.9 cm',
            medium: 'Oil On Canvas',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/on-the-other-side-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/on-the-other-side-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/on-the-other-side.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/on-the-other-side.webp`,
        },
        {
            id: 'vision.jpg',
            title: 'Vision',
            dimensions: 'H 45.7 x W 35.5 cm',
            medium: 'Oil On Canvas',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/vision-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/vision-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/vision.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/vision.webp`,
        },
        {
            id: 'girl-on-the-string.jpg',
            title: 'Girl On The String',
            dimensions: 'H 23 x W 23 cm',
            medium: 'Oil On Board',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/girl-on-the-string-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/girl-on-the-string-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/girl-on-the-string.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/girl-on-the-string.webp`,
        },
        {
            id: 'sleepwalker.jpg',
            title: 'Sleepwalker',
            dimensions: 'H 35.5 x W 27.9 cm',
            medium: 'Oil on Canvas',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/sleepwalker-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/sleepwalker-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/sleepwalker.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/sleepwalker.webp`,
        },
        {
            id: 'falling-into-grace.jpg',
            title: 'Falling Into Grace',
            dimensions: 'H 39.5 x W 28.9',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/falling-into-grace-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/falling-into-grace-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/falling-into-grace.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/falling-into-grace.webp`,
        },
        {
            id: 'prisoner.jpg',
            title: 'Prisoner',
            dimensions: 'H 39.1 x W 29.2 cm',
            medium: 'Acrylic, Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/prisoner-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/prisoner-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/prisoner.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/prisoner.webp`,
        },

        {
            id: 'untitled-1.jpg',
            title: 'Untitled #1',
            dimensions: 'H 39.1 x W 29.2 cm',
            medium: 'Acrylic, Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-1-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-1-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-1.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-1.webp`,
        },
        {
            id: 'we-are-coming-for-you.jpg',
            title: 'We Are Coming For You',
            dimensions: 'H 38.6 x W 28.8 cm',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/we-are-coming-for-you-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/we-are-coming-for-you-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/we-are-coming-for-you.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/we-are-coming-for-you.webp`,
        },
        {
            id: 'tethered-thumb.jpg',
            title: 'Tethered',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/tethered-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/tethered-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/tethered.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/tethered.webp`,
        },

        {
            id: 'untitled-5.jpg',
            title: 'Untitled #5',
            dimensions: 'H 57.5 x W 37.5 cm',
            medium: 'Acrylic, Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-5-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-5-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-5.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-5.webp`,
        },
        {
            id: 'dream.jpg',
            title: 'Dream',
            dimensions: 'H 91.5 x W 121.5 cm',
            medium: 'Acrylic, Pastel',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/dream-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/dream-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/dream.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/dream.webp`,
        },
        {
            id: 'untitled-6.jpg',
            title: 'Untitled #6',
            dimensions: 'H 76 x W 57 cm',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-6-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-6-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-6.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-6.webp`,
        },
        {
            id: 'roots.jpg',
            title: 'Roots',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/roots-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/roots-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/roots.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/roots.webp`,
        },
        {
            id: 'heavy-branch.jpg',
            title: 'Heavy Branch',
            dimensions: 'H 45.5 x W 61 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/heavy-branch-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/heavy-branch-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/heavy-branch.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/heavy-branch.webp`,
        },
        {
            id: 'untitled-7.jpg',
            title: 'Untitled #7',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Acrylic, Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-7-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-7-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-7.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-7.webp`,
        },
        {
            id: 'brother-and-sister.jpg',
            title: 'Brother and Sister',
            dimensions: 'H 76 x W 57 cm',
            medium: 'Acrylic, Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/brother-and-sister-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/brother-and-sister-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/brother-and-sister.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/brother-and-sister.webp`,
        },
        {
            id: 'leaving-me-in-darkness.jpg',
            title: 'Leaving me in Darkness',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/leaving-me-in-darkness-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/leaving-me-in-darkness-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/leaving-me-in-darkness.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/leaving-me-in-darkness.webp`,
        },
        {
            id: 'untitled-8.jpg',
            title: 'Untitled #8',
            dimensions: 'H 38.5 x W 47 cm',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-8-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-8-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-8.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-8.webp`,
        },
        // {
        //     id: 'escape.jpg',
        //     title: 'Escape TO CHANGE',
        //     dimensions: 'H 121 x W 91',
        //     medium: 'Acrylic, Ink Pen, Gouache',
        //     year: '2023',
        //     thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/escape-thumb.jpg`,
        //     srcPath: `${ASSET_IMAGES_PATH}/artwork/escape-thumb.jpg`
        // },
        {
            id: 'face-to-face.jpg',
            title: 'Face to Face',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/face-to-face-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/face-to-face-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/face-to-face.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/face-to-face.webp`,
        },
        {
            id: 'untitled-9.jpg',
            title: 'Untitled #9',
            dimensions: 'H 56.5 x W 39 cm',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-9-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-9-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-9.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-9.webp`,
        },
        {
            id: 'magic-machine.jpg',
            title: 'Magic Machine',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/magic-machine-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/magic-machine-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/magic-machine.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/magic-machine.webp`,
        },
        {
            id: 'untitled-10.jpg',
            title: 'Untitled #10',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-10-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-10-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-10.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-10.webp`,
        },
        {
            id: 'untitled-11.jpg',
            title: 'Untitled #11',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-11-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-11-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-11.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-11.webp`,
        },
        {
            id: 'look-at-me.jpg',
            title: 'Look at Me',
            dimensions: 'H 61 x W 45.5 cm (without frame)',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/look-at-me-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/look-at-me-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/look-at-me.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/look-at-me.webp`,
        },
        {
            id: 'transition.jpg',
            title: 'Transition',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/transition-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/transition-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/transition.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/transition.webp`,
        },
        {
            id: 'cat-lady.jpg',
            title: 'Cat Lady',
            dimensions: 'H 121.5 x W 91 cm',
            medium: 'Oil on Canvas',
            year: '2022',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/cat-lady-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/cat-lady-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/cat-lady.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/cat-lady.webp`,
        },
        {
            id: 'untitled-13.jpg',
            title: 'Untitled #13',
            dimensions: '-',
            medium: 'Ink Pen, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-13-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-13-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-13.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-13.webp`,
        },
        {
            id: 'untitled-14.jpg',
            title: 'Untitled #14',
            dimensions: '-',
            medium: 'Charcoal, Pencil, Gouache on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-14-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-14-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-14.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-14.webp`,
        },
        {
            id: 'untitled-15.jpg',
            title: 'Untitled #15',
            dimensions: 'H 61 x W 45.5 cm',
            medium: 'Acrylic, Ink Pen on Paper',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-15-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-15-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-15.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-15.webp`,
        },
        {
            id: 'untitled-16.jpg',
            title: 'Untitled #16',
            dimensions: 'H 121.5 x W 91 cm',
            medium: 'Acrylic, Pastel, Charcoal on Canvas',
            year: '2022',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-16-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-16-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-16.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-16.webp`,
        },
        {
            id: 'untitled-17.jpg',
            title: 'Untitled #17',
            dimensions: 'H 121.5 x W 91 cm',
            medium: 'Oil on Canvas',
            year: '2023',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-17-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-17-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-17.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-17.webp`,
        },
        {
            id: 'untitled-18.jpg',
            title: 'Untitled #18',
            dimensions: '-',
            medium: 'Pastel, Charcoal on Paper',
            year: '2022',
            thumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-18-thumb.jpg`,
            optimizedThumbnailSrcPath: `${ASSET_IMAGES_PATH}/artwork/thumb/untitled-18-thumb.webp`,
            srcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-18.png`,
            optimizedSrcPath: `${ASSET_IMAGES_PATH}/artwork/original/untitled-18.webp`,
        },
    ]
}