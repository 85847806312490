import React from 'react';
import { Page } from '../../components/page/Page';
import { Col, Container, Image, Row } from 'react-bootstrap';
import AboutPageCSS from './about-page.module.css'
import styled from 'styled-components';
import { ASSET_IMAGES_PATH } from '../../constants';

export interface AboutPageProps { }

const AboutPageWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 30px
`;

export const AboutPage = (props: AboutPageProps) => {
    return (
        <Page>
            <AboutPageWrapper>
                <Container>
                    <Row>
                        <Col lg='4'>
                            <Image className={AboutPageCSS.headshotImage} src={`${ASSET_IMAGES_PATH}/headshot.jpg`} alt="Artist's headshot photo." />
                            <p className={AboutPageCSS.quote} title='quote from the artist'>
                                - “An image comes to me - maybe from a dream or something I see
                                and it takes the form of an emotion. When I start to paint I don't have an
                                idea where I am going - the emotion leads me.”
                            </p>
                        </Col>
                        <Col lg='8'>
                            <section className={`float-end ${AboutPageCSS.bioText}`} title='Biography'>
                                <p>
                                    Dragan Strukelj ( b. 1985, Banja Luka, Bosnia ), lives and works in
                                    Manhattan, New York.
                                </p>
                                <p>
                                    -
                                </p>
                                <p>
                                    Dragan has found his voice in very ethereal representational work. Using oil, acrylics, and other mediums; 
                                    the dreamlike paintings become a story revelation even to him when the work is complete. 
                                    Through symbolic elements and painting from feelings; Dragan likes to invite viewers to reflect on their own inner worlds. 
                                </p>
                                <p>
                                    Consistent themes of identity, judgement and hope are always in the work. 
                                    There is often a large contrast between the subject matter and the overall feeling of the painting. 
                                    The effect is discomforting which is what is intended. 
                                </p>
                                <p>
                                    The colors are often very strong, but still there is a translucency suggesting movement or change. 
                                    The work clearly draws a journey of the body and soul and life quests that address the internal and external self. 
                                    It is the play between ourselves and our past and the journey to clear the patterns of the past to find the self.
                                    We are often so defined by our stories and our patterns and in his case; even cultural trauma; 
                                    but his is a journey of personal and global identity.
                                </p>
                                <p>
                                    In his magical surrealism, although more often used in literature; 
                                    Dragan presents the extraordinary in a context of an every day setting. 
                                    There is often a juxtaposition of things that are out of context; a merging of past and present 
                                    and something is a little “off”. 
                                </p>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </AboutPageWrapper>

        </Page>
    );
}