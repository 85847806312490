import { REQUIRED_MESSAGE } from '../form/validation/common.validation';

export const MESSAGE_MAX_LENGTH = 500;

export const nameValidation = {
    required: REQUIRED_MESSAGE,
    maxLength: {
        value: 40,
        message: 'Name cannot be longer than 40 characters.'
    }
};

export const subjectValidation = {
    maxLength: {
        value: 100,
        message: 'Subject cannot be longer than 100 characters.'
    }
};

export const messageValidation = {
    required: REQUIRED_MESSAGE,
    maxLength: {
        value: MESSAGE_MAX_LENGTH,
        message: 'Maximum message length exceeded.'
    }
};