import React from 'react';
import { Footer } from '../footer/Footer';
import { Outlet } from 'react-router-dom';
import { Header } from '../header/Header';

export interface RootProps { }

export const Root = (props: RootProps) => {
    return (
        <div className='website-font-face'>
            <Header />
            <Outlet></Outlet>
            <Footer />
        </div>

    );
}