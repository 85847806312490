export const REQUIRED_MESSAGE = 'Required field.'

export const emailValidation = {
    required: REQUIRED_MESSAGE,
    maxLength: {
        value: 250,
        message: 'E-mail cannot be longer than 50 characters.',

    },
    pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'E-mail address is invalid.'
    }
};