import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Instagram } from 'react-bootstrap-icons';
import { INSTAGRAM_LINK } from '../../constants';
import styled from 'styled-components';

export interface FooterProps { }

const InstagramIcon = styled(Instagram)`
    &:hover {
        fill: gray;
    }
`;

const FooterWrapper = styled.div`
    margin-top: 2%;
    font-size: 16px;
`;

export const Footer = (props: FooterProps) => {
    return (
        <FooterWrapper>
            <Container>
                <Row>
                    <Col xs='8'>
                        <p>All artwork &copy; Dragan Strukelj</p>
                    </Col>
                    <Col xs='4'>
                        <div className="float-end">
                            <a href={INSTAGRAM_LINK} target='_blank' rel="noreferrer">
                                <InstagramIcon color='darkgray' size='32' className=''></InstagramIcon>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </FooterWrapper>

    );
}