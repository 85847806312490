import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export interface PageProps {
    children?: ReactNode
}

const ChildNodeWrapper = styled.div`
    margin-top: 2%;
    margin-bottom: 2%;
`;

export const Page = (props: PageProps) => {

    return (
        <Container>
            <ChildNodeWrapper>
                {props.children}
            </ChildNodeWrapper >
        </Container >
    )
}

