import { Button, Image, Modal } from 'react-bootstrap';
import { ImageProperties } from '../../types/ImageProperties';
import ImageSlideCSS from './image-slide.module.css'
import { ArrowRightCircle, ArrowLeftCircle } from 'react-bootstrap-icons';


export interface ImageSlideProps {
    show: boolean;
    imageProperties?: ImageProperties;
    onNext: () => void
    onPrevious: () => void
    onClose: () => void
}

export const ImageSlide = (props: ImageSlideProps) => {
    return (
        <Modal
            className='website-font-face'
            dialogClassName={ImageSlideCSS.imageSlide}
            show={props.show}
            onHide={props.onClose}
        >
            <Modal.Header closeButton className={ImageSlideCSS.imageSlideHeader}>
                <Modal.Title>{props.imageProperties?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <picture>
                    <source srcSet={props.imageProperties?.optimizedSrcPath} />
                    <source srcSet={props.imageProperties?.srcPath} />
                    <Image src={props.imageProperties?.srcPath} className={ImageSlideCSS.bodyImage} alt={`Dragan Strukelj Original Artwork ${props.imageProperties?.title}`} />
                </picture>
                <h6 className={ImageSlideCSS.imageDescriptionTitle}>Dimensions</h6>
                <p className={ImageSlideCSS.imageDescription}>
                    {props.imageProperties?.dimensions}
                </p>
                <h6 className={ImageSlideCSS.imageDescriptionTitle}>Medium</h6>
                <p className={ImageSlideCSS.imageDescription}>
                    {props.imageProperties?.medium}
                </p>
                <h6 className={ImageSlideCSS.imageDescriptionTitle}>Year</h6>
                <p className={ImageSlideCSS.imageDescription}>
                    {props.imageProperties?.year}
                </p>
            </Modal.Body>
            <Modal.Footer className={`${ImageSlideCSS.imageSlideFooter}`}>
                <Button className={ImageSlideCSS.imageSlideButton} variant='light' onClick={() => { props.onPrevious() }}>
                    <span className={ImageSlideCSS.imageSlideButtonContent}><ArrowLeftCircle /> previous</span>
                </Button>
                <Button className={ImageSlideCSS.imageSlideButton} variant='light' onClick={() => { props.onNext() }}>
                    <span className={ImageSlideCSS.imageSlideButtonContent}> next <ArrowRightCircle /></span>
                </Button>
            </Modal.Footer>
        </Modal >
    );
}