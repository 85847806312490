import { createBrowserRouter } from "react-router-dom";
import { Root } from "./components/root/Root";
import { HomePage } from "./pages/home-page/HomePage";
import { ContactPage } from "./pages/contact/ContactPage";
import { AboutPage } from "./pages/about/AboutPage";

export enum PageRoute {
    ROOT = '/',
    CONTACT = '/contact',
    ABOUT = '/about'
}

export const ApplicationRoutes = createBrowserRouter([
    {
        path: "/",
        element: <Root></Root>,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/contact",
                element: <ContactPage />,
            },
            {
                path: "/about",
                element: <AboutPage />,
            },
        ]
    },
]);