import { API } from 'aws-amplify';
import { BaseService } from "./BaseService";
import { ContactSendMessageRequestDto, ContactSendMessageResponseDto } from "../dto/ContactSendMessage";


export class ContactService extends BaseService {

    static async sendMessage(contactSendMessageRequestDto: ContactSendMessageRequestDto): Promise<ContactSendMessageResponseDto> {
        return await API.post(this.API_NAME, this.CONTACT_ENDPOINT, { body: contactSendMessageRequestDto });
    }
}